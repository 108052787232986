<template>
  <div class="columns" v-if="hasRsomComputed">
    <div class="column is-one-third">
      <card-component
        title="1. Display prototypes"
        class="tile is-child"
        :displayHelp="$options.name"
      >
      <b-field
          label="Plot type"
        >
            <b-select
              v-model="plotType"
              expanded
            >
              <option value='color'>Color</option>
              <option value='mds'>Multidimensional scaling plot</option>
              <option value='poly.dist'>Distances with polygons</option>
            </b-select>
        </b-field>
        <div v-if='plotType==="color"'>
        <validation-observer v-slot="{ handleSubmit }">
          <validation-provider
            v-slot="{ errors, valid }"
            :rules= {required:true}
            name="variable"
          >
            <dataset-select v-show='plotType==="color"' :dataset="datasetName"
              :varname.sync="variable"
              v-model="variable"
              vartype='quanti'
              :withDataset="false"
              :label='"Select one variable"'
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :maxtags="1"
              :message="errors"
              :minlevel="2"
              labeltooltip="Non-constant quantitative variables"
              @select="option=>selectVarname(option)"
            ></dataset-select>
           </validation-provider>
          <hr/>
          <div class="has-text-centered">
            <b-button rounded
              class="is-primary button-shadow"
              @click.prevent="handleSubmit(run)"
            >
              <span class="icon is-small"><i class="fa fa-cogs"></i></span>
              <span> Explore individuals </span>
            </b-button>
          </div>
          </validation-observer>
        </div>
        <div class="has-text-centered" v-else>
          <b-button rounded
            class="is-primary button-shadow"
            @click.prevent="run"
          >
            <span class="icon is-small"><i class="fa fa-cogs"></i></span>
            <span> Explore individuals </span>
          </b-button>
        </div>
        </card-component>
    </div>
    <div class="column">
      <card-component title="2. Explore prototypes">
      </card-component>
      <error :type='"notifications"' />
      <card-no-result v-if="!resultsLoaded"/>
      <card-component  v-else-if="hasResults">
        <plotly
          v-if="graphicals.length===1"
          :data="graphicals[0]"
        ></plotly>
        <plotly-list
          v-else-if="graphicals.length >1"
          :data="graphicals"
        ></plotly-list>
        <nav class="level">
          <div class="level-left" />
          <div class="buttons level-right">
            <r-save-for-report
              label="Add to report"
              :objectName="objectSom"
              :params="params"
            />
          </div>
        </nav>
      </card-component>
    </div>
  </div>
</template>

<script>
import { ranalysis } from '@/mixins/ranalysis'
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'

export default {
  name: 'RPlotSomProto',
  props: {
    objectSom: {
      type: String,
      default: ''
    },
    datasetName: {
      type: String,
      default: ''
    }
  },
  components: {
    'validation-observer': ValidationObserver,
    'validation-provider': ValidationProvider,
    'card-component': () => import('@/components/ui/CardComponent.vue'),
    'card-no-result': () => import('@/components/ui/CardNoResult.vue'),
    'dataset-select': () => import('@/components/ui/DatasetSelect.vue'),
    'plotly-list': () => import('@/components/ui/PlotlyList.vue'),
    'plotly': () => import('@/components/rresults/Plotly.vue'),
    'r-save-for-report': () => import('@/components/RSaveForReport.vue'),
    'error': () => import('@/components/ui/Error.vue')
  },
  mixins: [ranalysis],
  data () {
    return {
      variable: '',
      plotType: 'color'
    }
  },
  computed: {
    hasRsomComputed: function () {
      if (this.datasetName === '' || this.datasetName === null) {
        return false
      } else {
        return true
      }
    },
    params: function () {
      let params = {
        'func_name': 'r_plotsomproto',
        'datasetName': this.objectSom,
        'out_graph': true,
        'type': this.plotType
      }
      if (this.plotType === 'color') {
        params['variable'] = this.variable
      }
      return params
    }
  },
  methods: {
    selectVarname: function (option) {
      this.variable = option.name
    }
  }
}
</script>
